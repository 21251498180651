




























import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import ProFolder from '@models/proRisk/ProFolder';

import FolderCard from '@/components/dashboard/FolderCard.vue';
import { VForm } from '@/types/v-form';
import Risk from '@models/proRisk/Risk';
import RiskForm from '@modules/professional-risk/form/RiskForm';
import RequestSaveForm from '@/utils/RequestSaveForm';

const riskProModule = namespace('riskPro');

@Component({
  components: {
    FolderCard,
  },
})
export default class ProFolderForm extends Vue {
  @riskProModule.State('historicProFolders')
  historicProFolders!: ProFolder[];

  @riskProModule.Action('postApiActiveProFolder')
  postApiActiveProFolder!: (form: any) => any;

  @riskProModule.Action('listHistoricProFolders')
  listHistoricProFolders!: () => Promise<any>;

  proFolderForm: any = {
    cloneId: '',
    reference: '',
  };

  $refs!: {
    form: VForm
  };

  get rules() {
    return {
      cloneId: [
        { required: true, message: 'Veuillez saisir une valeur', trigger: 'blur' },
      ],
      reference: [
        {
          required: true,
          message: 'Veuillez saisir une valeur ou choisir un dossier non vierge',
        },
      ],
    };
  }

  get folders() {
    return this.historicProFolders.slice(0).sort((a, b) => (a.endAt !== null && b.endAt !== null && a.endAt > b.endAt ? -1 : 1));
  }

  @Watch('proFolderForm.cloneId')
  onCloneChange(n: number | null, o: ProFolder) {
    if (n) {
      const folder = this.historicProFolders.find(p => p.id === n) || null;
      this.proFolderForm.reference = folder !== null ? folder.reference : '';
    }
  }

  submitForm() {
    let validForm = false;
    let errorFields = {};
    this.$refs.form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (!validForm) {
      return Promise.reject(errorFields);
    }

    return this.handleSave().then((response: any) => {
      this.$emit('valid');
      return response;
    });
  }

  @RequestSaveForm({
    validMsg: 'Données mise à jour.',
    errorMsg: 'Erreur lors de la création d\'un dossier.',
  })
  handleSave() {
    return this.postApiActiveProFolder(this.proFolderForm);
  }

  async created() {
    await this.listHistoricProFolders();
  }
}
