import { Prop, Component } from 'vue-property-decorator';
import DoughnutChart from '@/components/chart/DoughnutChart';

@Component
export default class RisksChart extends DoughnutChart {
  @Prop({ type: Array }) dataset!: Array<Number>;
  @Prop({ type: String, default: 'pie-risks-quotations' }) chartId!: string;

  get chartData(): any {
    return {
      labels: [
        'Risque non coté',
        'Risque faible',
        'Risque moyen',
        'Risque élevé',
      ],
      datasets: [
        {
          backgroundColor: [
            '#a3a3a3',
            '#35AA47',
            '#e3b62e',
            '#e13333',
          ],
          data: this.dataset,
          borderWidth: 0,
        },
      ],
    };
  }
}
