

















import {
  Component,
  Prop, PropSync,
  Vue,
} from 'vue-property-decorator';

import Modal from '@/components/Modal.vue';
import ProFolder from '@models/proRisk/ProFolder';

import ProFolderEditForm from '@modules/professional-risk/components/form/ProFolderEditForm.vue';

@Component({
  components: {
    Modal,
    ProFolderEditForm,
  },
})
export default class ProFolderEditModal extends Vue {
  @Prop({ type: Object })
  readonly proFolder!: ProFolder;

  @PropSync('showModal', { default: false })
  show!: boolean;

  close() {
    this.show = false;
  }
}
