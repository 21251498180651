







































import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import Layout from '@/layouts/LayoutDefault.vue';

import FolderHistory from '@/components/dashboard/FolderHistory.vue';
import ProFolderCard from '@modules/professional-risk/components/ProFolderCard.vue';
import ProFolderForm from '@modules/professional-risk/components/form/ProFolderForm.vue';

import ProFolder from '@models/proRisk/ProFolder';
import Firm from '@models/firm/Firm';

const riskProModule = namespace('riskPro');
const proFolderRepository = namespace('repositories/proFolder');

@Component({
  components: {
    Layout,
    ProFolderForm,
    ProFolderCard,
    FolderHistory,
  },
})
export default class DashboardView extends Vue {
  @riskProModule.Getter('getActiveProFolder')
  activeProFolder!: ProFolder | null;

  @proFolderRepository.Getter('getHistoricOrder')
  historicFolders!: (prop: string, direction: 'asc' | 'desc') => ProFolder[];

  @proFolderRepository.Action('findHistoricProFolders')
  findHistoricProFolder!: () => Promise<any>;

  @proFolderRepository.Action('findActiveProFolder')
  findActiveProFolder!: () => Promise<any>;

  @Getter('currentFirm')
  currentFirm !: Firm;

  refreshFolder() {
    this.findHistoricProFolder();
    this.findActiveProFolder();
  }

  created() {
    this.refreshFolder();
  }
}
