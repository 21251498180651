
























import _ from 'lodash';
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import ProFolder from '@models/proRisk/ProFolder';

import { VForm } from '@/types/v-form';
import RequestSaveForm from '@/utils/RequestSaveForm';
import * as Rules from '@/utils/CustomFormRules';

const proFolderRepository = namespace('repositories/proFolder');

@Component
export default class ProFolderEditForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly value!: ProFolder;

  @proFolderRepository.Action('insertOrUpdate')
  save!: (proFolder: ProFolder) => Promise<void>;

  buffer: ProFolder = new ProFolder();
  loading: boolean = false;

  $refs!: {
    form: VForm,
  };

  get rules() {
    return {
      reference: [
        { required: true, message: this.$t('form.required_field'), trigger: 'blur' },
      ],
      endAt: [
        {
          type: 'date',
          validator: Rules.dateGreaterThan,
          date: this.buffer.beginAt,
          message: this.$t('rules.greater_than', { label: 'la date d\'ouverture' }),
          trigger: 'blur',
        },
      ],
    };
  }

  @Watch('value', { immediate: true })
  onChangeValue(value: ProFolder) {
    this.buffer = _.cloneDeep(value);
  }

  validate(e: Event): Promise<any> {
    e.preventDefault();

    this.loading = true;

    let validForm = false;
    let errorFields = {};
    this.$refs.form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (!validForm) {
      this.loading = false;
      return Promise.reject(errorFields);
    }

    return this.saveForm().finally(() => {
      this.loading = false;
    });
  }

  @RequestSaveForm({
    validMsg: 'Modification effectuée',
    errorMsg: 'Erreur lors de la sauvegarde',
  })
  saveForm() {
    return this.save(this.buffer).then(() => {
      this.$emit('valid');
    });
  }
}
