
























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import FolderCard from '@/components/dashboard/FolderCard.vue';
import RisksChart from '@/components/chart/RisksChart';
import ActionPlansChart from '@/components/chart/ActionPlansChart';
import ProFolderEditModal from '@modules/professional-risk/components/modal/ProFolderEditModal.vue';
import ProFolder from '@models/proRisk/ProFolder';

const proRiskModule = namespace('riskPro');

@Component({
  components: {
    ProFolderEditModal,
    FolderCard,
    RisksChart,
    ActionPlansChart,
  },
})

export default class ProFolderCard extends Vue {
  @Prop({ type: Object, required: true })
  proFolder!: ProFolder;

  @Prop({ type: String })
  title!: string;

  @proRiskModule.Action('getPreviewPDF')
  getPreviewPDF!: (id: any) => any;

  @proRiskModule.Action('closeProFolder')
  closeProFolder!: (id: number) => any;

  showModal: boolean = false;

  editProFolder() {
    this.showModal = true;
  }

  answerCloseProFolder() {
    this.$confirm('Attention, cette action ne peut pas être annulée. Confirmez-vous la clôture du dossier ?', 'Attention', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Annuler',
      type: 'warning',
    }).then(async (response) => {
      if (!this.proFolder.endAt || !this.proFolder.beginAt) {
        this.$message({
          type: 'info',
          message: 'Vous devez saisir les dates de début et de fin de ce dossier afin poursuivre sa clôture.',
        });
      } else {
        await this.handleClosingFolder();
      }

      return response;
    }).catch((error) => {
      this.$message({
        type: 'info',
        message: 'Demande annulée',
      });
      throw error;
    });
  }

  get risksCount(): number {
    return this.proFolder.nbRisks;
  }

  get percentMade(): number {
    return this.proFolder.quotationCompletion;
  }

  get actionsCount(): number {
    return this.proFolder.nbActions;
  }

  get risksChart(): Record<number, number> {
    return this.proFolder.quotationDetails;
  }

  get actionPlansChart(): Record<number, number> {
    return this.proFolder.actionsDetails;
  }

  downloadPreviewPDF() {
    this.getPreviewPDF(this.proFolder.id);
  }

  handleClosingFolder() {
    return this.closeProFolder(this.proFolder.id ? this.proFolder.id : 0)
      .then((response: any) => {
        this.$emit('closed');
        return response;
      })
      .catch((error: any) => {
        this.$emit('closed-error');
      });
  }
}
