import { Prop, Component } from 'vue-property-decorator';
import DoughnutChart from '@/components/chart/DoughnutChart';

@Component
export default class ActionPlansChart extends DoughnutChart {
  @Prop({ type: Array })
  dataset!: Array<Number>;

  @Prop({ type: String, default: 'pie-action-plans' })
  chartId!: string;

  get chartData(): any {
    return {
      labels: [
        'Action non planifiée',
        'Action dépassée',
        'Action en cours',
        'Action terminée',
      ],
      datasets: [
        {
          backgroundColor: [
            '#aaaaaa',
            '#e13333',
            '#5208fb',
            '#35AA47',
          ],
          data: this.dataset,
          borderWidth: 0,
        },
      ],
    };
  }
}
